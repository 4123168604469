export const convertKgToLbs = (kg: number): number => Math.floor(kg * 2.20462262185)

export const convertLbsToKg = (lbs: number): number => Math.floor(lbs * 0.45359237)

export type FeetInches = { feet: number; inches: number }

export const convertCmToFeetAndInches = (cm: number): FeetInches => {
    const length = cm / 2.54
    const feet = Math.floor(length / 12)
    const inches = Math.round(length - 12 * feet)

    return {
        feet: feet > 0 ? feet : null,
        inches: inches > 0 ? Math.min(11, inches) : null,
    }
}

export function getBMICategory(bmi: number): { category: string; color: string; bgColor: string } {
    if (bmi < 18.5) {
        return { category: 'underweight', color: 'text-blue-500', bgColor: 'bg-blue-500' }
    } else if (bmi < 25) {
        return { category: 'normal', color: 'text-green-500', bgColor: 'bg-green-500' }
    } else if (bmi < 30) {
        return { category: 'overweight', color: 'text-yellow-500', bgColor: 'bg-yellow-500' }
    } else {
        return { category: 'obesity', color: 'text-red-500', bgColor: 'bg-red-500' }
    }
}

export const convertFeetInchesToCm = ({ feet, inches }: FeetInches): number => {
    const cm = Math.floor((feet ?? 0) * 30.48 + (inches ?? 0) * 2.54)
    return cm > 0 ? cm : null
}

export const formatNumber = (value: string): number | null => {
    const cleaned = (value ?? '').replace(/[^0-9]/g, '')
    const num = cleaned.length > 0 ? Number(cleaned) : NaN
    return num && num > 0 ? num : null
}

export const formatInches = (value: string): number | null => {
    const num = formatNumber(value) ?? 0
    return num && num > 11 ? 11 : num
}

export const calculateWeightGroup = (kg: number): { start: number; end: number } => {
    const weightTens = Math.trunc(kg / 10)
    const weightFraction = Math.round((kg / 10 - weightTens) * 10)
    const start = Math.max(0, weightTens * 10 - (weightFraction < 5 ? 10 : 0))
    const end = start + 20

    return { start, end }
}

export const calculateBMI = (weight: number, height: number): number => {
    return Math.floor(weight / (height * height))
}

export const calculateBMR = (weight: number, height: number, age: number, gender: string): number => {
    if (gender === 'female') {
        return 10 * weight + 6.25 * height - 5 * age - 161
    } else {
        return 10 * weight + 6.25 * height - 5 * age + 5
    }
}
//Multiply the BMR by the activity level to get the TDEE
export const calculateTDEECalories = (bmr: number, activityLevel: string): number => {
    let activityFactor: number
    switch (activityLevel) {
        case 'not_active':
            activityFactor = 1.2
            break
        case 'mid_active':
            activityFactor = 1.55
            break
        case 'very_active':
            activityFactor = 1.9
            break
        case 'max_active':
            activityFactor = 2.3
            break
        default:
            activityFactor = 1.2
    }

    return bmr * activityFactor
}

export function getAgeDecade(age: number) {
    const quotient = Math.floor(age / 10)
    if (quotient <= 2) {
        return 20
    } else if (quotient >= 7) {
        return 70
    } else {
        return quotient * 10
    }
}

const currenySymbols = {
    USD: '$',
    EUR: '€',
    CHF: 'CHF',
    GBP: '£',
    CAD: 'C$',
    AUD: 'A$',
    NOK: 'kr',
    SEK: 'kr',
    DKK: 'kr',
    PLN: 'zł',
    CZK: 'Kč',
}

export function currencySymbol(currency: string): string {
    if (currency.toUpperCase() in currenySymbols) return currenySymbols[currency.toUpperCase()]
    return currency
}
